.root_container {
  display: flex;
  width: 100vw;
  height: 91vh;
  flex-direction: row;
}
.sidebar_mainContainer {
  width: 0px;
  transition: all 0.5s;
}
.sidebar_mainContainer_active {
  width: 310px;
  overflow-y: scroll;
  scrollbar-width: none;
  transition: all 0.5s;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.right_content {
  overflow: auto;
  scrollbar-width: 0px;
  width: 100%;
  height: 100%;
  padding: 1rem;
}
.home_routesNmae {
  padding: 1rem;
  p {
    color: rgba(0, 0, 0, 0.7);
    font-size: 1.2rem;
  }
}
