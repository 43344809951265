.sidebar_container {
  width: 100%;
}
.navbar_searchInput_container {
  margin: 1rem;
  position: relative;

  .navbar_searchInput {
    color: #000;
    outline: none;
    background-color: rgba(255, 255, 255);
    border: none;
    border-radius: 0.6rem;
    padding: 0.7rem 1rem;
    font-size: 1rem;
    width: 100%;
  }
  .sidebar_serchtxt_container {
    padding: 0.4rem 0.5rem;
    width: 100%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      p {
        color: #000;
        font-weight: "400";
      }
    }
  }
  p {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.9);
    text-transform: capitalize;
    font-weight: 500;
  }
  .sidebar_searching_container {
    position: absolute;
    top: 2.8rem;
    width: 100%;
    .sidebar_search_pathname {
      font-size: 0.9rem;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
.sidebar_buttonWrap {
  padding: 0.8rem;
  border-left-width: 0.3rem;
  border-left-color: transparent;
  border-left-style: solid;
  cursor: pointer;
  .sidebar_icon {
    color: rgba(255, 255, 255, 0.541);
  }
  p {
    color: rgba(255, 255, 255, 0.541);
    text-transform: capitalize;
    font-weight: 200;
    margin-left: 0.6rem;
    letter-spacing: 0.075rem;
    font-size: 1.1rem;
    &:hover {
      color: #fff;
    }
  }
  &:hover {
    border-left-color: blue;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.sidebar_collapseItem {
  padding: 0.3rem;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  p {
    color: rgba(255, 255, 255, 0.541);
    text-transform: capitalize;
    font-weight: 200;
    margin-left: 1.3rem;
    letter-spacing: 0.075rem;
    font-size: 1rem;
    &:hover {
      color: #fff;
    }
  }
}
.active {
  border-left-color: blue;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}
.active_text {
  color: #fff;
}
.icon_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar_iconFirstWrap {
  display: flex;
  align-items: center;
}
