.navbar_container {
  height: 9vh;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  img {
    height: 3rem;
    width: 3rem;
    object-fit: cover;
  }
  h3 {
    color: #fff;
    margin-left: 1rem;
  }
  .menu_icon_container {
    margin-top: 0.2rem;
    margin-left: 1rem;
  }
  .menu_icon {
    color: #fff;
    font-size: 1.8rem;
  }
}
