@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,400;1,100&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
:root {
  --bg-color: whitesmoke;
}
.body {
  background-color: var(--bg-color);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* for FireFox */
input[type="number"] {
  -moz-appearance: textfield;
}
.tss-hwdp7s-MUIDataTable-liveAnnounce {
  height: 0 !important;
}
