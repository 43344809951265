.dashboard_card__container {
  border-radius: 1rem;
  width: 30%;
}
.dashboard_card_top {
  padding: 1rem 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-weight: 600;
    font-size: 1.6rem;
    color: #fff;
    letter-spacing: 1px;
  }
  h4 {
    font-weight: 600;
    color: #fff;
    font-size: 1.2rem;
    letter-spacing: 1px;
    margin-top: 0.7rem;
  }
}
.dashboard_card_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0 0 1rem 1rem;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  h5 {
    color: #fff;
    font-weight: 200;
    margin: 0.3rem 0;
  }
}
